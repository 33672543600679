@use "../base/variables" as v;
@use "../base/mixins" as m;

.header{
    text-align: center;
    background-color: v.$rojo;
    padding: 4rem 0;
    color: v.$blanco;
    @include m.desktop{
        img{
            height: 25rem;
        }
    }
}

.shadow{
    -webkit-box-shadow: 0px 5px 15px 5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 5px 15px 5px rgba(0,0,0,0.75);
    box-shadow: 0px 5px 15px 5px rgba(0,0,0,0.75);
    padding: 2rem;
    margin: 2rem auto;
    border-radius: 2rem;
}

.footer{
    color: v.$blanco;
    background-color: v.$rojo;
    padding: 1rem 0;
    text-align: center;
    margin-top: 1.5rem;
}

.formulario{
    background-color: v.$gris;
    border-radius: 2rem;
    padding: 5rem 1rem;
    svg{
        display: block;
        margin: 0 auto;
    }
    a{
        display: inline-block;
        text-align: center;
        width: 100%;
        margin: 1rem auto;
        color: v.$blanco;
        font-size: 2rem;
    }
}

.formulario-content{
    margin: 2rem 0;
    label{
        display: block;
        color: v.$blanco;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 1rem;
        text-align: center;
        @include m.desktop{
            text-align: left;
        }
    }
}

.formulario-input{
    width: 100%;
    padding: 1rem 1rem;
    border-radius: 1rem;
    border: none;
}

.boton-rojo-block{
    text-align: center;
    width: 100%;
    @include m.boton(v.$blanco, v.$rojo, block);
    @include m.desktop{
        width: auto;
        margin: 0 auto;
    }
}