@use "../base/variables" as v;
@use "../base/mixins" as m;

.alerta{
    color: v.$blanco;
    text-transform: uppercase;
    text-align: center;
    padding: 1rem 0;
    margin: 2rem auto;
}

.error{
    background-color: v.$rojo;
}

.correcto{
    background-color: v.$verde;
}