@mixin desktop{
    @media(min-width: 768px){
        @content;
    }
}

@mixin grid($columnas, $separacion){
    display: grid;
    grid-template-columns: repeat($columnas, 1fr);
    gap: $separacion;
}

@mixin boton($color, $bgcolor, $display){
    color: $color;
    background-color: $bgcolor;
    display: $display;
    text-transform: uppercase;
    padding: 1rem 2rem;
    margin: 1.5rem 0;
    border: none;
    border-radius: 1rem;
    transition: background-color .5s ease-in-out;
    &:hover{
        cursor: pointer;
        background-color: darken($bgcolor, 15%);
    }
}