@use "../base/variables" as v;
@use "../base/mixins" as m;

.punto{
    @include m.desktop{
        @include m.grid(3, 2rem);
    }
}

.no-mostrar{
    display: none;
}

.armada{
    label{
        display: inline-block;
        width: 50%;
    }
    input{
        width: 1.5rem;
        height: 1.5rem;
    }
}