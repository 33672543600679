@use "variables" as v;

html{
    font-size: 62.5%;
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}
body{
    font-size: 16px;
    font-family: v.$fuentePrincipal;
}
.contenedor{
    width: min(90%, 140rem);
    margin: 1rem auto;
}
a{
    text-decoration: none;
}
h1,h2,h3{
    text-align: center;
}
img{
    max-width: 100%;
}
fieldset{
    border: none;
}