@use "../base/variables" as v;
@use "../base/mixins" as m;

.ayuda{
    padding: 1rem;
    background-color: v.$gris;
    margin: 2rem auto;
    border-radius: 2rem;

    p{
        display: inline-block;
        color: v.$blanco;
        font-size: 2.5rem;
        font-weight: bold;
        border-bottom: 2px solid v.$blanco;
    }

    a{
        display: block;
        color: v.$blanco;
        font-size: 2rem;
        margin: 2rem 0;
    }

    h2{
        background-color: v.$rojo;
        border-radius: 1rem;
        color: v.$blanco;
        padding: 1rem 0;
        font-size: 3rem;
        text-transform: uppercase;
    }
}

.importante{
    color: v.$rojo;
    font-size: 2rem;
    font-weight: bold;
    text-decoration: underline;
}

.navegacion{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    background-color: darken(v.$rojo, 5%);
    a{
        padding: 1rem 2rem;
        color: v.$blanco;

        &:hover{
            background-color: darken(v.$rojo, 10%);
            border-radius: 1rem;
        }
    }
    @include m.desktop{
        flex-direction: row;
        justify-content: center;
    }
}

.layer{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(rgb(0, 0, 0), 60%);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ventanaModal{
    padding: 2rem;
    width: 90%;
    margin: 0 auto;
    background-color: white;
    border-radius: 1.5rem;
    @include m.desktop{
        width: 80rem;
    }
}

.timer{
    font-size: 2rem;
    font-weight: bold;
}